<template>
  <div id="attendance-history" >
    <div class="body">
      <div class="history-header-container" :class="{ 'hidden-navbar': !showNavbar && !loading }">
        <div class="left-option">
          <section class="title-container">
            <Icon :tooltip="opened ? 'Fechar histórico' : 'Abrir histórico'">
              <BackBurger
                v-if="!hideBurger"
                :class="{ 'back-burger': true, opened: opened }"
                @click="handleHistoryVisibility"
              />
            </Icon>
            <div class="form-title">Histórico</div>
          </section>
          <div class="body-content">
            <div class="container">
              <ModulesSelect />
              <div class="filter-options">
              <div>
                <div class="select-input">
                  <div v-if="options.length || loadingDates"
                    @click="() => { openedOptions = true }"
                    v-click-outside="() => { openedOptions = false }"
                  >
                    <b-form-select
                      v-model="selected"
                      :options="options"
                      class="input-element"
                      value-field="item"
                      text-field="name"
                      :disabled="loadingDates || busy"
                      disabled-field="notEnabled"
                      @input="(value) => selectedChanged(value)"
                    />
                    <b-spinner
                      v-if="loadingDates || busy"
                      variant="primary"
                      class="spinner spinner-position"
                    >
                    </b-spinner>
                    <ChevronDown
                      v-else
                      class="caret"
                      :class="{ rotateUp:openedOptions, rotateDown:!openedOptions }"
                    />
                  </div>
                  <b-form-input
                    v-else-if="!options.length"
                    class="input-element"
                    placeholder="Nenhuma opção disponível"
                    disabled
                  ></b-form-input>
                </div>
              </div>

              <div>
                <validation-provider
                  name="visualizar"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    id="visualizar"
                    v-model="view"
                    :options="types"
                    :close-on-select="false"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :multiple="true"
                    placeholder="Visualizar"
                    class="multiple"
                    :class="{ 'error': touched && errors.length > 0 && 1 === 2 }"
                    :disabled="loading"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="selection" slot-scope="{ values }">
                      <span class="inline-content">{{ values.join(', ') }}</span>
                    </template>
                    <template slot="noOptions">
                      Nenhuma opção
                    </template>
                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                </validation-provider>
              </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-content"  v-infinite-scroll="() => loadMore()" :infinite-scroll-disabled="busy" infinite-scroll-distance="5">
        <div class="container">
          <div v-if="filteredAttendances.length" >
            <div class="medical-record"
              v-for="(procedure, index) in filteredAttendances" :key="index" :id="`medical-record${procedure.id}`"
              >
              <section ref="medicalRecordContainer">
              <div v-if="typeHasMedicalRecords(procedure.type)">
                <div class="body-head">
                  <div class="doctor-container" >
                    <p>
                      {{ typeTranslation[procedure.type] }}
                      <span v-if="procedure.professional">
                        realizada por
                      </span>
                    </p>
                    <p v-if="procedure.professional">
                      Dr. {{ procedure.professional.name }}
                    </p>
                  </div>
                  <div class="time-container">
                    <p>Data da consulta</p>
                    <p>{{ moment(procedure.start_datetime).format('DD/MM/YYYY')}}</p>
                  </div>
                </div>
                <History :records="procedure.medical_records" />
              </div>
              <div v-else-if="procedure.type === 'annotation' && validType">
                <div class="note-container">
                  <div class="doctor-container">
                    <p>Anotação de equipe multidisciplinar realizada por</p>
                    <p>{{ procedure?.professional?.name }}</p>
                  </div>
                  <div class="time-container">
                    <p>Data da anotação</p>
                    <p>{{ moment(procedure?.start_datetime).format('DD/MM/YYYY')}}</p>
                  </div>
                    <p>{{ procedure?.content }}</p>
                </div>
              </div>
            </section>
            </div>
          </div>
          <b-row v-else class="m-3 pt-3 justify-content-center">
            <div class="doctor-container">
              <p>Nenhum histórico por aqui ainda</p>
            </div>
          </b-row>
          <b-row v-if="loading" class="m-3 pt-3 justify-content-center">
            <b-spinner variant="primary" ></b-spinner>
          </b-row>
        </div>
      </div>
    </div>
    <HistoryAppointmentDocsModal :attendance="attendance" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment'

export default {
  components: {
    History: () => import('@/components/MedicalRecords/Modal/HistoryModal'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Icon: () => import('@/components/General/Icon'),
    BackBurger: () => import('@/assets/icons/back-burger.svg'),
    HistoryAppointmentDocsModal: () => import('@/components/General/HistoryAppointmentDocsModal'),
    ModulesSelect: () => import('@/components/Attendance/Forms/Modules/ModulesSelect'),
  },
  props: {
    patientId: String,
    types: {
      type: Array,
      default: () => ['Consultas', 'Pré-consulta', 'Anotações', 'Consulta cirúrgica', 'Exames']
    },
    hideBurger: Boolean,
  },
  computed: {
    ...mapState({
      opened: state => state.attendance.history.opened,
      modules: state => state.attendance.history.modules,
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.historyElement = document.getElementById('history-container')
      if (this.historyElement){
        this.lastScrollPosition = this.historyElement.scrollTop
        this.historyElement.addEventListener('scroll', this.onScroll)
      }
    })
  },
  beforeDestroy () {
    this.historyElement.removeEventListener('scroll', this.onScroll)
  },
  data() {
    return {
      touched: false,
      errors: [],
      view: [
        'Consultas', 
        'Pré-consulta', 
        'Anotações', 
        'Consulta cirúrgica', 
        'Evolução do anestesista',
        'Evolução da equipe multidisciplinar',
        'Anotações da equipe multidisciplinar',
        'Exames'
      ],
      parseType: {
        'ATTENDANCE':'Consultas',
        'PRE_CONSULTATION':'Pré-consulta',
        'SURGERY':'Consulta cirúrgica',
        'ANESTHETIST_EVOLUTION': 'Evolução do anestesista',
        'TEAM_EVOLUTION':'Evolução da equipe multidisciplinar',
        'TEAM_ANNOTATIONS':'Anotações da equipe multidisciplinar',
        'annotation':'Anotações',
        'EXAM': 'Exames'
      },
      loading: false,
      loadingDates: true,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      attendance: null,
      annotation: null,
      dates: [],
      selected: String,
      options: [],
      active: String,
      busy: false,
      attendances:[],
      filteredAttendances: [],
      lastOptionIndex: 0,
      showNavbar: true,
      lastScrollPosition: 0,
      historyElement: document.getElementById('history-container'),
      openedOptions: false,
      typeTranslation: {
        'ATTENDANCE': 'Consulta',
        'PRE_CONSULTATION': 'Pré-consulta',
        'annotation': 'Anotação',
        'SURGERY': 'Consulta cirúrgica',
        'ANESTHETIST_EVOLUTION': 'Evolução do anestesista',
        'TEAM_EVOLUTION': 'Evolução da equipe multidisciplinar',
        'TEAM_ANNOTATIONS': 'Anotações',
        'EXAM': 'Exame'
      },
      validType: false,
    }
  },
  methods: {
    ...mapActions('attendance/history', ['handleHistoryVisibility']),
    clearData() {
      this.touched = false;
      this.errors = [];
      this.view = this.types;
      this.attendance = null;
      this.annotation = null;
      this.dates = [];
      this.selected = String;
      this.options = [];
      this.active = String;
      this.busy = false;
      this.attendances =[];
      this.lastOptionIndex = 0;
      this.showNavbar = true;
      this.lastScrollPosition = 0;
      this.filteredAttendances = []
    },
    async selectedChanged(value) {
      if(!value) return;
      this.openedOptions = false
      await this.loadMore(value)
      this.goToOption(value)
    },
    onScroll () {
      const element = this.historyElement
      if (element.scrollTop < 0 || Math.abs(element.scrollTop - this.lastScrollPosition) < 60) return
      this.showNavbar = element.scrollTop < this.lastScrollPosition
      this.lastScrollPosition = element.scrollTop
    },
    typeHasMedicalRecords(attendanceType) {
      return [
        'ATTENDANCE', 
        'PRE_CONSULTATION', 
        'SURGERY', 
        'ANESTHETIST_EVOLUTION', 
        'TEAM_EVOLUTION',
        'TEAM_ANNOTATIONS',
        'EXAM'
      ].includes(attendanceType)
    },
    async loadMore(id = null) {
      if (this.busy) return

      this.busy = true;

      const targetIndex = id ? this.options.findIndex(el => el.item === id) : this.lastOptionIndex + 5

      while(targetIndex > this.lastOptionIndex && this.lastOptionIndex < this.options.length) {
        const lastOption = this.options[this.lastOptionIndex];
        if(!lastOption) break;
        if ( this.typeHasMedicalRecords(lastOption.type) ){
          await this.getMedicalRecord(lastOption.item);
        } else if (lastOption.type === 'annotation'){
          await this.getAnnotation(lastOption.item)
        }
        this.lastOptionIndex++;
      }
      this.busy = false
    },

    async goToOption(value) {
      const forms = document.querySelector('.history-container')
      const element = document.getElementById(`medical-record${value}`)
      const header = document.querySelector('.history-header-container')
      if(!element ) {
        this.loading = true;

        await this.loadMore()
        forms.scrollTo({
          top: document?.getElementById(`medical-record${value}`)?.offsetTop - forms?.offsetTop - (header ? header?.offsetHeight : 0),
          behavior: 'smooth'
        });
        this.loading = false;
        return
      }
      forms.scrollTo({
        top: element.offsetTop - forms.offsetTop - (header ? header.offsetHeight : 0),
        behavior: 'smooth'
      });
    },
    async getMedicalRecord(attendanceId) {
      this.loading = true
      try {
        const res = await this.api.getAttendanceMedicalRecord(attendanceId)
        if(!this.attendances.find(att => att.id === res.data.id))
          this.attendances.push(res.data)
      } catch (err) {
        console.log(err.message)
      } finally {
        this.getFilteredAttendances()
        this.loading = false
      }
    },
    async getAnnotation(annotationId){
      this.loading = true
      try {
        const res = await this.api.getAnnotation(annotationId)
        if (!this.attendances.find(att => att.id === res.data.id)) {
          this.$set(res.data, 'type', 'annotation')
          this.attendances.push(res.data)
        }
      } catch (err) {
        console.log(err.message)
      } finally {
        this.getFilteredAttendances()
        this.loading = false
      }
    },
    async createSelectionOptions(){
      this.loadingDates = true

      const selectedIndex = this.options.findIndex((el) => {
        if(el.item === this.selected){
          return el
        }
      })
      this.attendances=[]
      await this.getFilteredAttendances()

      if(this.options[selectedIndex]){

        let found = false

        if(!this.view.find(el => el === this.parseType[this.options[selectedIndex].type] )){
          for(let i = selectedIndex; i < this.options.length; i++){
            if(this.options[i].type !== this.options[selectedIndex].type){
              this.selected = this.options[i].item
              found = true
              break
            }
          }
          if(!found){
            for(let j = 0; j < this.options.length; j++){
              if(this.options[j].type !== this.options[selectedIndex].type){
                this.selected = this.options[j].item
                break
              }
            }
          }
        }
      }

      this.options.splice(0, this.options.length)

      this.dates.map((date) => {
        if(this.view.find(el => el === this.parseType[date.type] )){
          this.options.push({
            item: date.id,
            type: date.type,
            name: moment(date.date).format(`[${this.typeTranslation[date.type]}] DD [de] MMMM YYYY [às] HH[h]mm`),
          })
        }
      })
      this.lastOptionIndex = 0
      this.selected = this.options.length ? this.options[0].item : ''
      await this.selectedChanged(this.selected);
      this.loadingDates = false
    },
    async getPatientAttendanceDate(patientId, clinicId){
      const types = []
      Object.keys(this.typeTranslation)
        .filter(type => type !== 'annotation')
        .map(type => {
          if(this.view.find(el => {return el === this.typeTranslation[type]}))
            types.push(type)
        })

      this.loadingDates = true
      try {
        const records = this.modules.map(el => el.id)
        const res = await this.api.getPatientAttendanceDates(patientId, clinicId, types, records)
        this.dates = (JSON.parse(JSON.stringify(res.data)))
        this.dates.sort(function(a,b) {
          return new Date(b.date) - new Date(a.date)
        })
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        this.loadingDates = false
      }
    },
    async getFilteredAttendances () {
      const procedures = this.attendances.filter(el => {
        return this.view.find(v => {
          return v === this.parseType[el?.type]
        })
      })

      this.filteredAttendances = procedures.filter(procedure => {
        return (
          procedure.type === 'annotation'
          || procedure.medical_records?.some(el => {
            return this.modules.some(module => {
              return (module.id === 'exame-personalizado' ? el.type.includes('exame-personalizado') : module.id === el.type)
            })
          })
        )
      })

      this.filteredAttendances.map(el => {
        if (el.medical_records) {
          el.medical_records = el.medical_records.filter(mr => {
            let hasFilledValue = false

            if(typeof mr.value === 'object' && !Array.isArray(mr.value)){
              hasFilledValue = Object.keys(mr.value).some(key => key !== 'simplified' && mr.value[key])
            }
            else if(Array.isArray(mr.value)) {
              mr.value.forEach(value => {
                if(
                  (typeof value === 'object' && !Array.isArray(value) && Object.values(value).filter(val => !!val)?.length) ||
                  (Array.isArray(value) && value.filter(val => !!val)?.length) ||
                  (typeof value !== 'object' && !Array.isArray(value) && !!value)
                ) hasFilledValue = true
              });
            }
            else{
              if(mr.value) hasFilledValue = true
            }

            return this.modules.some(module =>
              (module.id === 'exame-personalizado' && mr.type.includes('exame-personalizado'))
              || (hasFilledValue && module.id === mr.type)
            )
          })
        }
      })

      this.filteredAttendances.sort((a,b) => {
        return new Date(b.start_datetime) - new Date(a.start_datetime)
      })

      if (!this.filteredAttendances.length && this.modules?.length)
        await this.loadMore()
    },
    async handleGetDates() {
      if (this.patientId) {
        this.busy = true
        this.loading = true
        this.clearData();
        await this.getPatientAttendanceDate(this.patientId, this.clinic.id)
        this.busy = false
        this.loading = false
        }
        const teste = this.modules.some(option => option.id === 'anotacao-cirurgica')
        if (teste && this.validType === false) {
          this.validType = true
        } else {
          this.validType = false
        }
    }
  },
  watch: {
    modules: {
      async handler(){
        await this.handleGetDates()
        await this.getFilteredAttendances()
      },
      deep: true,
    },
    async view(newValue) {
      if (newValue) {
        await this.createSelectionOptions()
      }
    },
    async dates(newValue) {
      if (newValue) {
        await this.createSelectionOptions()
      }
    },
    patientId: {
      async handler () {
        if(this.modules)
          await this.handleGetDates()
      },
      immediate: true
    },
    types: {
      async handler (value) {
        if(value)
          this.view = value
      },
      deep: true
    },
  },
}
</script>

<style lang="scss">

#attendance-history {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);

  .modal-content {
    width: 800px !important;
  }

  .multiselect {
    .custom-item {
      color: var(--blue-500);
      display: flex;
      align-items: center;
      font-weight: 600;

      .icon {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
        margin-right: 12px;
      }
    }
  }

  .chevron {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-600);
    cursor: pointer;
    transition: all 0.5s;
  }

  @media(max-height: 800px){
    height: 120vh !important;
  }

  .medical-record {

    .record-title {
      color: var(--dark-blue);
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 50px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: var(--dark-blue);
    }

    .doctor-container {
        /* margin: 20px 0; */
        flex-direction: row;
        outline: none;

        > p:nth-child(1){
          font-size: 18px;
          font-weight: 700;
          color: var(--blue-600);
        }

        > p:nth-child(2){
          font-size: 18px;
          font-weight: 600;
          color: var(--dark-blue);
          margin-bottom: 20px
        }
    }

    .time-container {
        /* margin: 20px 0; */
        flex-direction: row;
        outline: none;

        > p:nth-child(1){
          font-size: 18px;
          font-weight: 700;
          color: var(--blue-600);
        }

        > p:nth-child(2){
          font-size: 18px;
          font-weight: 600;
          color: var(--dark-blue);
          margin-bottom: 20px
        }
    }

    .note-container {
        margin: 20px 0;
        flex-direction: row;
        outline: none;

        > p:nth-child(1){
          font-size: 18px;
          font-weight: 700;
          color: var(--blue-600);
        }

        > p:nth-child(2){
          font-size: 18px;
          font-weight: 600;
          color: var(--dark-blue);
          margin-bottom: 20px
        }
    }


  }

  .loading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80%;
  }

  .body {
    display: flex;
    flex-direction: column;

    .history-header-container {
      transition: transform 1s;
      position: sticky;
      top: 0;
      width: 100%;
      background-color: var(--neutral-000);
      z-index: 10;
      flex-direction: column;

      .left-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .back-burger {
          width: 24px;
          height: 24px;
          fill: var(--blue-500);
          cursor: pointer;
          transition: all 600ms;
          &.opened {
            transform: rotate(180deg);
          }
        }

        .title-container {
          width: 100%;
          margin: 20px 0 0 20px;
          display: flex;
          flex-direction: row;
        }
        .form-title {
          font-family: Red Hat Display;
          font-weight: 700;
          font-size: 1.2rem;
          color: var(--dark-blue);
          margin-bottom: 0;
        }
      }
      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 0;

        .modal-title {
          font-size: 28px;
          color: var(--dark-blue);
          font-weight: 700;
        }

        .changeBtn-left {
            color: var(--blue-500);
            font-weight: 700;
            margin: 0 30px 0 0;
        }

        .changeBtn-right {
            color: var(--blue-500);
            font-weight: 700;
        }

        p {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .history-header-container.hidden-navbar {
      box-shadow: none;
      transform: translate3d(0, -100%, 0);
    }
    .body-content {
      width: 100%;
      display: flex;
      flex-direction: column;

      .inline-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 20ch;
      }

      .container {
        display: flex;
        flex-direction: column;
        padding-top: 4px;
        max-width: 100%;

        .filter-options {
          width: 100%;
          display: flex;
          flex-direction: row;

          > div:nth-child(1) {
            width: 60%;
            padding-right: 10px;
            > input {
              width: 100%;
            }
          }
          > div:nth-child(2) {
            width: 40%;
            > input {
              width: 100%;
            }
          }
        }

        .body-head {
          width: 100%;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid var(--neutral-200);

          > div:nth-child(1) {
            width: 60%;
            padding-right: 10px;
            > input {
              width: 100%;
            }
          }
          > div:nth-child(2) {
            width: 40%;
            > input {
              width: 100%;
            }
          }
        }

        .title-body-content {
          color: var(--dark-blue);
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
        }

        .multiple {
          border: 1px solid #C6CEEB;
          border-radius: 8px;
          margin: 8px 0 24px 0;
          height: 38px !important;
          box-sizing: border-box;

          .multiselect__tags {
            height: 100%;

          }
        }

        .input-element {
          cursor: pointer;
          border: 1px solid #C6CEEB;
          border-radius: 8px;
          height: 38px;
          outline: none;
          color: var(--type-active);
          margin: 8px 0 24px 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;

          &:disabled {
            background-color: var(--neutral-000);
          }
        }


        .select-input {
          cursor: pointer;
          .chevron {
            position: relative;
            margin-left: -50px;
              margin-bottom: 20px;

            svg {
              stroke: var(--neutral-500);
            }
          }
        }

          .close-icon {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }


  }
}

.footer {
  .footer-content {
    display: flex;
    justify-content: right;
    margin: 24px;

    button {
      width: auto;
      height: auto;
      padding: 14px 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      border-radius: 8px;
      background-color: var(--blue-500);
      color: var(--neutral-000);
    }
  }
}

.spinner {
  pointer-events: none;
  position: absolute;
  background-color: var(--neutral-000);
  width: 22px !important;
  height: 22px !important;

}

.spinner-position {
  margin-left: -35px;
  margin-top: 16px;
}

.caret {
  pointer-events: none;
  position: absolute;
  width: 24px;
  height: 24px;
  stroke: var(--neutral-500);
  margin-left: -35px;
  margin-top: 16px;
  background-color: var(--neutral-000);
}

.rotateUp {
  animation: rotateUp .5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rotateDown {
    animation: rotateDown .5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes rotateUp {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotateDown {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
    }

</style>


