<template>
  <div class="history-container" id="history-container" :class="{ hidden: !opened }">
    <AttendanceHistory :patientId="attendance ? attendance.patient.id : null" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AttendanceHistory from '@/layouts/AttendanceHistory'

export default {
  components: {
    AttendanceHistory,
  },
  computed: {
    ...mapState({
      opened: state => state.attendance.history.opened,
      attendance: state => state.attendance.attendance,
    })
  },
}
</script>
<style lang="scss" scoped>
  .history-container {
    width: 100%;
    padding: 0px 8px 8px 8px;
    background: white;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid var(--neutral-200);

    &.hidden {
      display: none !important;
    }

  }
</style>
